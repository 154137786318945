<template>
  <div class="body">
    <b-tabs horizontal nav-wrapper-class="nav-bottom">
      <b-tab active title="Plan General Info">
        <div class="body">
          <b-row class="d-flex justify-content-between header mb-2">
            <b-col>
              <h1 v-if="orgInfo.name" class="serverName text-primary ml-2 mt-1">
                {{ `${orgInfo.id} - ${orgInfo.name.toUpperCase()}` }}
              </h1>
              <b-skeleton v-else></b-skeleton>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-between ml-2">
            <div>
              {{ t("org-info.created_by") }}
              <strong
                class="text-primary pl-47 pr-47"
                v-if="orgInfo.created_by"
              >
                {{ orgInfo.created_by.username }}
              </strong>
              <b-skeleton v-else></b-skeleton>
              {{ t("org-info.at") }}
              <strong
                class="text-primary pl-47 pr-47"
                v-if="orgInfo.created_at"
              >
                {{ orgInfo.created_at }}
              </strong>
              <b-skeleton v-else></b-skeleton>
            </div>
            <div class="mr-2">
              {{ t("org-info.updated_at") }}
              <strong
                class="text-primary pl-47 pr-47"
                v-if="orgInfo.updated_at"
              >
                {{ orgInfo.updated_at }}
              </strong>
              <b-skeleton v-else></b-skeleton>
            </div>
          </b-row>

          <hr class="my-2" />

          <b-col cols="10" class="mb-2">
            <b-row>
              <b-col lg="8" md="8" sm="12" cols="12" class="mb-2 mt-1">
                <b-form-group
                  label="Grandx Identifier"
                  label-for="gx-id"
                  label-cols-lg="6"
                  label-cols-md="6"
                  label-cols-sm="4"
                >
                  <b-input-group v-if="orgInfo" class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="GlobeIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="gx-id"
                      readonly
                      :value="`  ${
                        orgInfo.grandx_id ? orgInfo.grandx_id : 'Not Allocated'
                      }`"
                    />
                    <b-input-group-append is-text>
                      <b-button
                        variant="ml-1 btn btn-warning text-white py-25 px-75"
                        @click="editOrg('Grandx_Id')"
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <div v-else>
                    <b-skeleton type="input"></b-skeleton>
                  </div>
                </b-form-group>
              </b-col>

              <b-col lg="8" md="8" sm="12" cols="12">
                <b-form-group
                  label="Organization Name"
                  label-for="org-name"
                  label-cols-lg="6"
                  label-cols-md="6"
                  label-cols-sm="4"
                >
                  <b-input-group v-if="orgInfo.name" class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="BoxIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="org-name"
                      placeholder="Organization Name"
                      :value="`    ${orgInfo.name}`"
                      readonly
                    />
                    <b-input-group-append is-text>
                      <b-button
                        variant="ml-1 btn btn-warning text-white py-25 px-75"
                        @click="editOrg('Name')"
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <div v-else>
                    <b-skeleton type="input"></b-skeleton>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </div>
      </b-tab>
      <b-tab title="Users" lazy>
        <div v-if="getCurrentOrg">
          <b-row class="d-flex justify-content-between header mb-2 ml-2 mt-2">
            <h1 class="text-primary">USERS</h1>
            <b-button
              class="mr-2"
              variant="relief-success"
              @click="handleAddUserToOrg"
            >
              Add User
            </b-button>
          </b-row>

          <hr class="my-2" />

          <BsTable
            tableName="orgUsers"
            :fields="tableFields.orgUsers"
            :actions="actions.orgUsers"
            :has_paginator="false"
            :show_filter="true"
          />
        </div>
      </b-tab>
    </b-tabs>

    <base-modal />
    <hr class="my-2" />
  </div>
</template>

<script>
import BsTable from "@/layouts/components/BsTable.vue";
import BaseModal from "@/layouts/components/BaseModal.vue";
import custom from "@/custom";
import EventBus from "@/custom/eventBus";
import { makeToast } from "@/layouts/components/Popups";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { mapGetters } from "vuex";

import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BRow,
  BCol,
  BSkeleton,
  BTabs,
  BTab,
  BCardText,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

const { t } = useI18nUtils();

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BInputGroupAppend,
    BFormTextarea,
    BaseModal,
    BsTable,
    BSkeleton,
    BTabs,
    BTab,
    BCardText,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      orgInfo: [],
      t,
      tableFields: {
        orgUsers: [
          {
            key: "id",
            label: this.$t("common.table.fields.id"),
            sortable: false,
          },
          {
            key: "fullname",
            label: this.$t("common.table.fields.name"),
            sortable: false,
          },
          {
            key: "created_at",
            label: this.$t("common.table.fields.created_at"),
            sortable: false,
          },
          {
            key: "role",
            label: this.$t("organization.table.org_user.role"),
            sortable: false,
          },
          {
            key: "email",
            label: this.$t("organization.table.org_user.email"),
            sortable: false,
          },
          {
            key: "actions",
            label: this.$t("common.table.fields.actions"),
          },
        ],
      },
      actions: {
        orgUsers: custom.tableActions.orgUsers,
      },
    };
  },

  created() {
    this.$root.$refs[this.$route.meta.pageTitle] = this;
  },

  computed: {
    ...mapGetters(["getCurrentOrg"]),
  },

  mounted() {
    this.fetchOrganizationById();
  },

  methods: {
    init() {
      this.fetchOrganizationById();
      EventBus.$emit("reloadOrg");
    },
    fetchOrganizationById() {
      this.$store
        .dispatch("getOrganizationById", this.$route.params)
        .then((response) => {
          console.log(response);
          this.$store.commit("SET_CURRENT_ORG", response);
          this.orgInfo = response;
        })
        .catch((error) => {
          makeToast({
            title: this.$t("organization.toast.get.error.title"),
            text: this.$t("organization.toast.get.error.message"),
            variant: "success",
            icon: "CheckIcon",
          });
        });
    },
    editOrg(action) {
      this.$store.dispatch("setOrgAction", action).then(() => {
        this.$store
          .dispatch("setModalObj", `editOrganization${action}`)
          .then(() => {
            this.$store.dispatch("setActiveModal", true);
          })
          .catch(() => {
            console.log(error);
          });
      });
    },
    handleAddUserToOrg() {
      this.$store
        .dispatch("setModalObj", `addUserToOrganization`)
        .then(() => {
          this.$store.dispatch("setActiveModal", true);
        })
        .catch(() => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>